import React from 'react';
import Axios from 'axios';
import _ from 'lodash';
import Horse from './horse';
import Result from './result';

class Race extends React.Component {
  state = {
    horses: [],
    race: this.props.race
  };

  renderTimeTo = (race) => {
    let results = race.results
    let time = race.time_to
    if (results) {
      return 'Resulted'
    }
    if (time == null) {
      return time
    } else {
      let t = time // time to race in minutes
      if (t < 0) {
        return 'Pending'
      } else if (t == 0) {
        return `Now`
      } else if (t < 60) {
        return `${Number(t).toFixed()} Mins`
      } else {
        return `${Number(t/60).toFixed()} Hrs`
      }
    }
  }

  componentDidMount = () => {
    let race = this.props.race;
    this.getRace(race)
  }
  
  componentDidUpdate = (prevProp, prevState) => {
    if (prevProp.race != this.props.race) {
      this.getRace(this.props.race)
    }
  }

  getPlacePays = (horses) => {
    return horses.length > 7 ? 3 : 2
  }

  getRace = (race) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/api/race?raceKey=${race.race_key}`)
       .then((res) => {
         this.setState({horses: res['data']['data']})
       })
  }

  renderMeetingInfo = () => {
    return (
      <h2>{this.props.race.name}</h2>
    )
  }

  beautifyResult = (res) => {
    let pos = parseInt(res)
    if (pos === 1) {
      return <span className='font-weight-bold text-success'>{res}</span>
    } else if ([2,3].includes(pos)) {
      return <span className='font-weight-bold text-warning'>{res}</span>
    } else {
      return null
    }
  }

  isHorseTipped = (horse) => {
    let tip = this.props.tips.filter((x) => x.runner_key == horse.runner_key)
    if (tip.length > 0) {
      return true
    } else {
      return false
    }
  }

  renderActiveHorses = () => {
    return this.state.horses.map((x) => {
      if (!x.win_deduction) {
        return <Horse timeTo={this.props.race.time_to} horse={x} isScratched={false} isTipped={this.isHorseTipped(x)} curPortfolio={this.props.curPortfolio} getTips={this.props.getTips}/>
      }
    })
  }

  renderScratchedHorses = () => {
    return this.state.horses.map((x) => {
      if (x.win_deduction) {
        return <Horse horse={x} isScratched={true} isTipped={this.isHorseTipped(x)} />
      }
    })
  }

  renderResults = (results) => {
    if (results) {
      return <span> [{results}]</span>
    } else {
      return ""
    }
  }

  renderResultsSection = () => {
    if (this.props.race.results) {
      return <Result tips={this.props.tips} placePays={this.getPlacePays(this.state.horses)} results={this.state.horses.filter((x) => [1,2,3,4].includes(parseInt(x.result))).sort((x,z) => parseInt(x.result) > parseInt(z.result) ? 1 : -1)}/>
    } else {
      return null
    }
  }

  renderRaceHeader = () => {
    if (this.props.race.race_code === 1) {
      return (
        <div className='race-header'>
          <img className='thoroughbred-icon' src='/thoroughbred.png'></img>
          <p><b>{this.props.race.meeting}</b></p>
          <p><span className='text-muted'>TRACK:</span><span> {this.props.race.track}</span></p>
          <p><span className='text-muted'>DISTANCE:</span><span> {this.props.race.distance}m</span></p>
        </div>
      )
    } else if (this.props.race.race_code === 4) {
      return (
        <div className='race-header'>
          <img className='thoroughbred-icon' src='/greyhound.png'></img>
          <p><b>{this.props.race.meeting}</b></p>
          <p><span className='text-muted'>TRACK:</span><span> {this.props.race.track}</span></p>
          <p><span className='text-muted'>DISTANCE:</span><span> {this.props.race.distance}m</span></p>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="offset-md-2 col-md-8 col-xs-12 text-center">
        <div className='race-info-container'>
          {this.renderRaceHeader()}
          <div className='race-details'>
            <p><span className='text-highlight'>Race {this.props.race.race}</span> | {this.props.race.race_name} | {this.renderTimeTo(this.props.race)} {this.renderResults(this.props.race.results)}</p>
          </div>
          <hr />
          {this.renderResultsSection()}
        </div>
        <table className='text-center table table-sm horse-table meetings-table rounded'>
          <tbody>
            <tr>
              <th>Image</th>
              <th>Runner</th>
              <th>Open</th>
              <th>Win</th>
              <th>Place</th>
              <th>Add</th>
            </tr>
            {this.renderActiveHorses()}
            {this.renderScratchedHorses()}
          </tbody>
        </table>
      </div>
    );
  }
};


export default Race;
