import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Axios from 'axios';
import './App.scss';
import Horses from './components/horses';
import Meetings from './components/meetings';
import Landing from './components/landing';
import Header from './components/header';
import SecondaryHeader from './components/secondaryHeader';
import Login from './components/login';
import Portfolios from './components/portfolio/portfolios';
import Tips from './components/tips';
import SignUp from './components/signup';
import Stats from './components/stats';
import Graph from './components/graph';

class App extends React.Component {

  getPathFromLocalStorage = () => {
    if(localStorage['path']) {
      return localStorage['path']
    }
  }

  state = {showStats: false, path: this.getPathFromLocalStorage(), user: null, loggedIn: false, curPortfolio: null, tips: [], portfolios: [], graphData: null, graphPeriod: null, graphType: null}
  
  checkIfValid = () => {
    if (localStorage['authentication']) {
      Axios.get(`${process.env.REACT_APP_API_URL}/api/users/verify`, {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}).then((res) => {
        if(res['data']['response'] === 'success') {
          this.setState({user: res['data']['data']})
        }
      });
    }
  }

  getTips = () => {
    if (this.state.curPortfolio) {
		  let curPortfolioId = this.state.curPortfolio.id == null ? 1000 : this.state.curPortfolio.id
      Axios.get(`${process.env.REACT_APP_API_URL}/api/portfolios/${curPortfolioId}/tips`,
      {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}
      ).then((res) => {
        this.setState({tips: res['data']['data']})
        this.getGraphData(this.state.curPortfolio)
      })
    } else {
      this.setState({tips: []})
    }
  }

  getGraphData = (_portfolio, userId=this.state.user.id, period=this.state.graphPeriod, type=this.state.graphType) => {
		let portfolio_id = _portfolio.id == null ? 1000 : _portfolio.id
    if (localStorage['authentication']) {
      Axios.get(`${process.env.REACT_APP_API_URL}/api/portfolio/${portfolio_id}/graph?period=${period}&type=${type}&userId=${userId}`, {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}).then((res) => {
        if(res['data']['response'] === 'success') {
          this.setState({graphData: res['data']['data']})
        }
      });
    }
  }

  setPeriod = (period) => {
    this.setState({graphPeriod: period}, () => {
      this.getGraphData(this.state.curPortfolio, this.state.user.id, this.state.graphPeriod, this.state.graphType)
    })
  }

  setGraphType = (type) => {
    this.setState({graphType: type}, () => {
      this.getGraphData(this.state.curPortfolio, this.state.user.id, this.state.graphPeriod, this.state.graphType)
    })
  }

  getPortfolioData = () => {
    if (localStorage['authentication']) {
      let portfolioId = localStorage['portfolioId']*1
      Axios.get(`${process.env.REACT_APP_API_URL}/api/portfolios`, {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}).then((res) => {
        if(res['data']['response'] === 'success') {
          this.setState({portfolios: res['data']['data']}, () => {
            let visiblePortfolios = this.state.portfolios.filter((x) => x.visible === 1)
            let portfolioIds = visiblePortfolios.map((x) => x.id)
            let curPortfolio = portfolioIds.includes(portfolioId) ? visiblePortfolios.filter((x) => x.id == portfolioId)[0] : visiblePortfolios[0]
            this.getCurPortfolio(this.state.portfolios[0])
          })
        }
      });
    }
  }
  
  componentDidMount = () => {
    this.checkIfValid();
    if (process.env.REACT_APP_CURRENT_ENV === 'development') {
	    document.title = 'BETLOCKER DEVELOPMENT'
	    document.querySelector('link[rel="shortcut icon"]').href='/icon.png'    
    };
  };

  logout = () => {
    this.setState({loggedIn: false, user: null})
    localStorage.removeItem('authentication')
    localStorage.removeItem('portfolioId')
  }

  login = () => {
    this.setState({loggedIn: true, path: 'tips'})
    this.checkIfValid()
  }

  getCurPortfolio = (porf) => {
    if (porf) {
      localStorage['portfolioId'] = porf.id
      this.setState({curPortfolio: porf}, () => { this.getTips() });
    }
  }

  renderHorsies = () => {
    if (this.state.curPortfolio) {
      return (
        <div>
          <br />
          <Horses tips={this.state.tips} curPortfolio={this.state.curPortfolio} getTips={this.getTips} />
        </div>
      )
    } else {
      return
    }
  }

  setPath = (path) => {
    localStorage['path'] = path
    this.setState({path: path})
  }

  toggleStats = () => {
    this.setState({showStats: !this.state.showStats})
  }
  
  renderPortfolios = () => {
    return (
      <div>
        <Header user={this.state.user} portfolios={this.state.portfolios} curPortfolio={this.state.curPortfolio} getPortfolioData={this.getPortfolioData} getCurPortfolio={this.getCurPortfolio} logout={this.logout} getTips={this.getTips} toggleStats={this.toggleStats} showStats={this.state.showStats}/>
        <SecondaryHeader path={this.state.path} setPath={this.setPath} logout={this.logout} />
        <div className="App container-fluid">   
          <Portfolios portfolios={this.state.portfolios} getPortfolioData={this.getPortfolioData} curPortfolio={this.state.curPortfolio}/>
        </div>
      </div>
    )    
  }

  renderTips = () => {
    return (
      <div>
        <Header  user={this.state.user} portfolios={this.state.portfolios} curPortfolio={this.state.curPortfolio} getPortfolioData={this.getPortfolioData} getCurPortfolio={this.getCurPortfolio} logout={this.logout} getTips={this.getTips} toggleStats={this.toggleStats} showStats={this.state.showStats}/>
        <SecondaryHeader path={this.state.path} setPath={this.setPath} logout={this.logout}/>
        <div className="App container-fluid">   
          <Tips tips={this.state.tips} curPortfolio={this.state.curPortfolio} />
        </div>
      </div>
    )
  }
  
  renderStats = () => {
    return (
      <div>
        <Header user={this.state.user}  portfolios={this.state.portfolios} curPortfolio={this.state.curPortfolio} getPortfolioData={this.getPortfolioData} getCurPortfolio={this.getCurPortfolio} logout={this.logout} getTips={this.getTips} toggleStats={this.toggleStats} showStats={this.state.showStats}/>
        <SecondaryHeader path={this.state.path} setPath={this.setPath} logout={this.logout} />
        <div className="App container-fluid">   
          <Stats curPortfolio={this.state.curPortfolio} showStats={this.state.showStats}/> 
          <Graph period={this.state.graphPeriod} setPeriod={this.setPeriod} setType={this.setGraphType} type={this.state.graphType} graphData={this.state.graphData} showStats={this.state.showStats}/>
        </div>
      </div>
    )
  }

  renderMeetings = () => {
    return (
      <div>
        <Header user={this.state.user}  portfolios={this.state.portfolios} curPortfolio={this.state.curPortfolio} getPortfolioData={this.getPortfolioData} getCurPortfolio={this.getCurPortfolio} logout={this.logout} getTips={this.getTips} toggleStats={this.toggleStats} showStats={this.state.showStats}/>
        <SecondaryHeader path={this.state.path} setPath={this.setPath} logout={this.logout} />
        <div className="App container-fluid">   
          {this.renderHorsies()}     
          <Meetings tips={this.state.tips} curPortfolio={this.state.curPortfolio} getTips={this.getTips}/>
        </div>
      </div>
    )
  }

  renderDefault = () => {
    return (
      <div>
        <Header user={this.state.user}  portfolios={this.state.portfolios} curPortfolio={this.state.curPortfolio} getPortfolioData={this.getPortfolioData} getCurPortfolio={this.getCurPortfolio} logout={this.logout} getTips={this.getTips} toggleStats={this.toggleStats} showStats={this.state.showStats}/>
        <SecondaryHeader path={this.state.path} setPath={this.setPath} logout={this.logout}/>
        <div className="App container-fluid">   
        </div>
      </div>
    )
  }

  renderController = () => {
    if (this.state.path == 'portfolios') {
      return this.renderPortfolios()
    } else if (this.state.path == 'tips') {
      return this.renderTips()
    } else if (this.state.path == 'stats') {
      return this.renderStats()
    } else if (this.state.path == 'meetings') {
      return this.renderMeetings()
    } else {
      return this.renderDefault()
    }
  }

  render() {
    return (
      <Router>
        <Route exact path='/'>
          <Landing user={this.state.user}/>
        </Route>
        <Route path='/app'>
          {this.renderController()}
        </Route>
        <Route path='/(signup|login)'>
          <div>
            <div className="App">
              <div className="row">
                <div className="col-md-12 text-center">
                  <Switch>
                    <Route path='/signup'>
                      <h1>Sign Up</h1>
                      <br />
                      <SignUp login={this.login} user={this.state.user}/>
                      <br />
                      <Link to='/login' className='text-muted link'>Already have an account? Log In</Link>
                    </Route>
                    <Route path='/login'>
                      <h1>Login</h1>
                      <br />
                      <Login login={this.login} user={this.state.user}/>
                      <br />
                      <Link to='/signup' className='text-muted link'>Don't have an account? Sign Up</Link>
                    </Route>
                  </Switch>
                  <div className='w-50-auto'>
                    <hr />
                  </div>
                  <Link className='text-muted link' to='/'>go back</Link>
                </div>
              </div>
            </div>
          </div>
        </Route>
      </Router>
    );
  }
}

export default App;
