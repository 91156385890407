import React from 'react';
import Axios from 'axios';
import _ from 'lodash';

class Horse extends React.Component {

  calcPercentage = (odds, current) => {
    if (!odds || !current) {
      return 
    } else {
      let dif = ((parseFloat(odds) - parseFloat(current)) / parseFloat(odds)) * 100
      if (dif > 0) {
        return (
          <span className="text-success">{`${dif.toPrecision(2)}%`}</span>
        )
      } else if (dif < -100) {
        return (
          <span className="text-danger">{`${dif.toPrecision(3)}%`}</span>
        ) 
      } else if (dif < 0) {
        return (
          <span className='text-danger'>{`${dif.toPrecision(2)}%`}</span>
        )
      } else {
        return (
          <span className="text-warning">{`${dif.toPrecision(2)}%`}</span>
        )
      }
    }
  }

  beautifyResult = (res) => {
    let pos = parseInt(res)
    if (pos === 1) {
      return <span className='font-weight-bold text-success'>{res}</span>
    } else if ([2,3].includes(pos)) {
      return <span className='font-weight-bold text-warning'>{res}</span>
    } else {
      return null
    }
  }

  addTip = (e) => {
    let runnerKey = e.target.className.split('--').pop()
    if (!this.props.curPortfolio) {
      window.alert("Please create a portfolio before you enter your bets.")
    } else {
      let portfolioId = this.props.curPortfolio.id
      let ev = e.target;
      if (portfolioId) {
        e.target.disabled = true;
        e.target.innerHTML = "<div class='spinner-border'></div>"
        Axios.post(`${process.env.REACT_APP_API_URL}/api/portfolios/${portfolioId}/tips/create`,
        {runner_key: runnerKey},
        {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}
        ).then((res) => {
          ev.disabled = false;
          ev.innerHTML = '+';
	        if (!res['data'].response) {
            window.alert("You can't build there, somethings in the way.")
	        } else {
            this.props.getTips();
	        }
        })
      }
    }
  }

  renderButton = (horse, isTipped, isScratched) => {
    if (isScratched || this.props.timeTo < 5 || horse.win === null) {
      return <span>{this.props.horse.scratched_at}</span>

    }
    if (isTipped) {
      return <button className={'btn btn-sm bg-success'}><span className='fa fa-check'></span></button>
    } else {
      return <button className={`btn btn-sm horse-id--${horse.runner_key}`} onClick={this.addTip}> + </button>
    }
  }

  renderIsTipped = (isTipped) => {
    if (isTipped) {
      return <span className='fa fa-circle horse-in-race fa-highlighted'></span>
    } else {
      return null
    }
  }

  renderRunnerName = (x, isScratched) => {
    if (isScratched) {
      return <del>{x.number}. {x.runner} ({x.br})</del>
    } else {
      return <b>{x.number}. {x.runner} ({x.br})</b>
    }
  }

  renderImageCell = () => {
    let x = this.props.horse
    let race_code = this.props.horse.race_code
    if (race_code === 1) {
      return (
        <td className='jockey-img-cell'>
          <img src={this.props.horse.image}></img>
          <span className='form text-muted'>{x.form}</span>
        </td>
      )
    } else if (race_code === 4) {
      return (
        <td>
          <div className='box-img'>
            <span className='box-number'>{x.number}</span>
          </div>
          <span className='form text-muted'>{x.form}</span>
        </td>
      )
    }
  }


  renderWinOdds = () => {
    if (this.props.isScratched) {
      return (
        <>
          <span>$0.{this.props.horse.win_deduction}</span>
        </>
      )
    } else if (this.props.horse.win_sp) {
      return this.props.horse.win_sp
    } else {
      return this.props.horse.win
    }
  }

  renderPlaceOdds = () => {
    if (this.props.isScratched) {
      return `$0.${this.props.horse.place_deduction}`
    } else if (this.props.horse.place_sp) {
      return this.props.horse.place_sp
    } else {
      return this.props.horse.place
    }
  }

  render() {
    let x = this.props.horse
    let isTipped = this.props.isTipped
    let isScratched = this.props.isScratched
    let winData = x.win_sp ? x.win_sp : x.win
    let placeData = x.place_sp ? x.place_sp : x.place
    return (
      <tr className={isScratched ? 'text-danger' : ''}>
        {this.renderImageCell()}
        <td>
          {this.renderIsTipped(isTipped)}
          <div className='runner-result'>{x.result ? this.beautifyResult(x.result) : null}</div>
          {this.renderRunnerName(x, isScratched)}
          <p className='text-muted no-margin'>J. {x.jockey} {x.weight}Kg</p>
          <p className='text-muted no-margin'>T. {x.trainer}</p>
        </td>
        <td>
          <p className='text-muted'>{x.win_open}</p>
          {this.calcPercentage(x.win_open, winData)}
        </td>
        <td>
          <p className='margin-auto'>{this.renderWinOdds()}</p>
        </td>
        <td>
          <p className='margin-auto'>{this.renderPlaceOdds()}</p>
        </td>
        <td> {this.renderButton(x, isTipped, isScratched)}</td>
      </tr>
    )
  }
}

export default Horse;
