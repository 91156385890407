import React from 'react';
import Axios from 'axios';
import Portfolio from './portfolio';
import CreatePortfolio from './createPortfolio';

class Portfolios extends React.Component {
  state = {portfolioNameInput: ''}

  handlePortfolioNameInput = (e) => {
    this.setState({portfolioNameInput: e.target.value})
  }

  createPortfolio = (e) => {
    e.preventDefault()
    Axios.post(`${process.env.REACT_APP_API_URL}/api/portfolios/create`,
      {name: this.state.portfolioNameInput},
      {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}).then((res) => {
        if(res['data']['response'] === 'success') {
          this.props.getPortfolioData()
        }
    })
  }

  deletePortfolio = () => {
    const conf = window.confirm(`Are you sure you wish to delete ${this.props.curPortfolio.name}?\n\nThis will also delete all the portfolios associated tips.`);
    if (conf === true) {
      Axios.delete(`${process.env.REACT_APP_API_URL}/api/portfolios/delete`,
      {
        data: {
          "portfolio_id": this.props.curPortfolio.id
        },
        headers: {
          'Authorization': `bearer ${localStorage['authentication']}`,
          'Content-Type': 'application/json'}
        }).then((res) => {
          if(res['data']['response'] === 'success') {
            this.props.getPortfolioData()
          }
    })
    }
  }

  renderPortfolios = () => {
    return this.props.portfolios.map((x) => {
      return <Portfolio curPortfolio={x} getPortfolioData={this.props.getPortfolioData}/>
    })
  }

  render() {
    return (
      <div className="container">
          <div className="row">
            <div className="offset-md-2 col-md-8 text-center">
              <h2>Your Portfolios</h2>
              <br />
            </div>
          </div>
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <table className='text-center table table-sm horse-table rounded'>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Total Bets</th>
                    <th>POT</th>
                    <th>Hide/Show</th>
                    <th>Save</th>
                    <th>Delete</th>
                  </tr>
                  {this.renderPortfolios()}
                </tbody>
              </table>
              <br />
            </div>
          </div>
          <CreatePortfolio getPortfolioData={this.props.getPortfolioData}/>
        <br />
      </div>
    );
  }
};


export default Portfolios
