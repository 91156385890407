import React from 'react';
import Axios from 'axios';

class SecondaryHeader extends React.Component {

  renderNav = (path) => {
    if (this.props.path == path) {
      return <p className='header-nav-link header-nav-link-active' onClick={() => { this.props.setPath(path)}}>{path.charAt(0).toUpperCase() + path.slice(1)}</p>
    } else {
      return <p className='header-nav-link' onClick={() => { this.props.setPath(path)}}>{path.charAt(0).toUpperCase() + path.slice(1)}</p>
    }
  }

  render() {
    return (
      <div className='secondary-header-container'>
        <div className="header-navigation">
          {this.renderNav('tips')}
          {this.renderNav('meetings')}
          {this.renderNav('portfolios')}
          {this.renderNav('stats')}
          <p className='header-nav-link' onClick={this.props.logout}>Logout</p>
        </div>
      </div>
    );
  }
};

export default SecondaryHeader
