import React from 'react';
import Axios from 'axios';

class Portfolio extends React.Component {
  state = {portfolioNameInput: '', portfolioVisible: ''}

  componentDidMount() {
    this.setState({portfolioNameInput: this.props.curPortfolio.name, portfolioVisible: this.props.curPortfolio.visible})
  }

  handlePortfolioNameInput = (e) => {
    this.setState({portfolioNameInput: e.target.value})
  }
  
  componentDidUpdate = (prevProp, prevState) => {
    if (prevProp.curPortfolio != this.props.curPortfolio) {
      this.setState({portfolioNameInput: this.props.curPortfolio.name, portfolioVisible: this.props.curPortfolio.visible})
    }
  }

  createPortfolio = (e) => {
    e.preventDefault()
    Axios.post(`${process.env.REACT_APP_API_URL}/api/portfolios/create`,
      {name: this.state.portfolioNameInput},
      {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}).then((res) => {
        if(res['data']['response'] === 'success') {
          this.props.getPortfolioData()
        }
    })
  }

  updatePortfolio = (e) => {
    e.preventDefault()
    Axios.post(`${process.env.REACT_APP_API_URL}/api/portfolios/${this.props.curPortfolio.id}/update`,
      {name: this.state.portfolioNameInput, visible: this.state.portfolioVisible},
      {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}).then((res) => {
        if(res['data']['response'] === 'success') {
          this.props.getPortfolioData()
        }
    })
  }

  deletePortfolio = () => {
    const conf = window.confirm(`Are you sure you wish to delete ${this.props.curPortfolio.name}?\n\nThis will also delete all the portfolios associated tips.`);
    if (conf === true) {
      Axios.delete(`${process.env.REACT_APP_API_URL}/api/portfolios/delete`,
      {
        data: {
          "portfolio_id": this.props.curPortfolio.id
        },
        headers: {
          'Authorization': `bearer ${localStorage['authentication']}`,
          'Content-Type': 'application/json'}
        }).then((res) => {
          if(res['data']['response'] === 'success') {
            this.props.getPortfolioData()
          }
    })
    }
  }

  renderVisibility = () => {
    if (this.state.portfolioVisible) {
      return <td onClick={() => this.setState({portfolioVisible: 0})}><span className='far fa-eye'></span></td>
    } else {
      return <td onClick={() => this.setState({portfolioVisible: 1})}><span className='far fa-eye-slash'></span></td>
    }
  }

  renderUpdate = () => {
    if (this.props.curPortfolio.name !== this.state.portfolioNameInput || this.props.curPortfolio.visible !== this.state.portfolioVisible) {
      return <td onClick={this.updatePortfolio}><span className='fa fa-check'></span></td> 
    } else {
      return <td></td>
    }
  }

  render() {
    let x = this.props.curPortfolio
    return (
      <tr>
        <td><input type='text' value={this.state.portfolioNameInput} onChange={this.handlePortfolioNameInput}></input></td>
        <td>{x.total}</td>
        <td>{(x.winPOT*100).toPrecision(3)}%</td>
        {this.renderVisibility()}
        {this.renderUpdate()}
        <td onClick={this.deletePortfolio}><span className='fa fa-trash text-danger'></span></td>
      </tr>
    )
  }
}

export default Portfolio;
