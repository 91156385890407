import React from 'react';
import { Redirect } from "react-router-dom";
import Axios from 'axios';

class Landing extends React.Component {
  render() {
    if (this.props.user) {
      return <Redirect to='/app' />
    } else {
      return (
        <div>
          <nav className="navbar navbar-expand-lg navbar-light static-top">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img src="/blue_icon.png" alt style={{width: '50px'}}/>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="#">
                      Home
                      <span className="sr-only">(current)</span>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='btn btn-app' href='/login'>App</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

        <header className="masthead text-white text-center">
          <div className="overlay" />
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <h1 className="">
                  Welcome to Betlocker
                </h1>
                <p className='header-subtitle'>The easiest place to track your bets</p>
                <a className='btn btn-app mt-3' href='/signup'>Get Started</a>
              </div>
            </div>
          </div>
        </header>
         {/* Icons Grid */}
         <section className="features-icons bg-light text-center">
           <h2> Getting Started Is Easy</h2>
           <br />
           <div className="container">
             <div className="row">
               <div className="col-lg-4">
                 <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                   <div className="features-icons-icon d-flex">
                     <i className="fas fa-edit m-auto text-highlight" />
                   </div>
                   <h3>Add your bets</h3>
                   <p className="lead mb-0">Add your bets with our easy to use interface.</p>
                 </div>
               </div>
               <div className="col-lg-4">
                 <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                   <div className="features-icons-icon d-flex">
                     <i className="fas fa-chart-area m-auto text-highlight" />
                   </div>
                   <h3>Track your bets</h3>
                   <p className="lead mb-0">We show you the time until your bets run, your odds vs the current market odds and any deductions that apply.</p>
                 </div>
               </div>
               <div className="col-lg-4">
                 <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                   <div className="features-icons-icon d-flex">
                     <i className="fas fa-sliders-h m-auto text-highlight" />
                   </div>
                   <h3>Analyse your Results</h3>
                   <p className="lead mb-0">Check your results in our statistics panel, with all the metrics you need to stay ahead of the game.</p>
                 </div>
               </div>
             </div>
           </div>
         </section>
         {/* Image Showcases */}
         <section className="showcase">
           <div className="container-fluid p-0">
             <div className="row no-gutters">
               <div className="col-lg-6 order-lg-2 text-white showcase-img" style={{backgroundImage: 'url("img/bg-showcase-1.jpg")'}} />
               <div className="col-lg-6 order-lg-1 my-auto showcase-text">
                 <h2>Who is Betlocker For?</h2>
                 <p className="lead mb-0">Betlocker is for anyone who's looking to take their punting to the next level. The first step to becoming a better punter is tracking your results. Betlocker takes away the hassle involved and does the hard work for you. </p>
               </div>
             </div>
             <div className="row no-gutters">
               <div className="col-lg-6 text-white showcase-img" style={{backgroundImage: 'url("img/bg-showcase-2.jpg")'}} />
               <div className="col-lg-6 my-auto showcase-text">
                 <h2>No Cost, Ever.</h2>
                 <p className="lead mb-0">Betlocker is not a business, but a free tool to help punters get better. Because of this we will never charge for using the site.</p>
               </div>
             </div>
             <div className="row no-gutters">
               <div className="col-lg-6 order-lg-2 text-white showcase-img" style={{backgroundImage: 'url("img/bg-showcase-3.jpg")'}} />
               <div className="col-lg-6 order-lg-1 my-auto showcase-text">
                 <h2>Easy to Use &amp; Customize</h2>
                 <p className="lead mb-0">With the ability to create as many portfolios as you like, you can track multiple different strategies and compare them against each other.</p>
               </div>
             </div>
           </div>
         </section>
         {/* Footer */}
         <footer className="footer bg-text">
           <div className="container">
             <div className="row">
               <div className="col-lg-6 h-100 text-center text-lg-left my-auto">
                 <ul className="list-inline mb-2">
                   <li className="list-inline-item">
                     <a href="/">Home</a>
                   </li>
                   <li className="list-inline-item">⋅</li>
                   <li className="list-inline-item">
                     <a href="/login">Login</a>
                   </li>
                   <li className="list-inline-item">⋅</li>
                   <li className="list-inline-item">
                     <a href="/signup">Sign Up</a>
                   </li>
                 </ul>
                 <p className="text-muted small mb-4 mb-lg-0">© Betlocker 2020. All Rights Reserved.</p>
               </div>
               <div className="col-lg-6 h-100 text-center text-lg-right my-auto">
                 <ul className="list-inline mb-0">
                   <li className="list-inline-item mr-3">
                     <a href="#">
                       <i className="fab fa-facebook fa-2x fa-fw" />
                     </a>
                   </li>
                   <li className="list-inline-item mr-3">
                     <a href="#">
                       <i className="fab fa-twitter-square fa-2x fa-fw" />
                     </a>
                   </li>
                   <li className="list-inline-item">
                     <a href="#">
                       <i className="fab fa-instagram fa-2x fa-fw" />
                     </a>
                   </li>
                 </ul>
               </div>
             </div>
           </div>
         </footer>
       </div>
    );     
    }

  }
}

export default Landing;
