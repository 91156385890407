import React from 'react';
import { Line } from 'react-chartjs-2';

class Graph extends React.Component {
  state = {showGraph: this.props.showStats, period: this.props.period, type: this.props.type}
  
  getOptions = () => {
    return {
      scales: {
        xAxes: [{
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {'day': 'MM/YY'},
            },
            gridLines: {
              display: true
            }
        }],
        yAxes: [{
          gridLines: {
              display: true
          }   
        }]
      }
    }
  }

  renderTypeSelect = () => {
    if (this.props.type === 'place' || this.props.type == null) {
      return (<><span className='clickable' onClick={() => this.props.setType('win')}>Win</span> | <span className='font-weight-bold clickable' onClick={() => this.props.setType('place')}>Place</span></>)
    } else {
      return (<><span className='font-weight-bold clickable' onClick={() => this.props.setType('win')}>Win</span> | <span className='clickable' onClick={() => this.props.setType('place')}>Place</span></>)
    }
  }

  renderPeriodSelect = () => {
    return (
      <>
      <span className={this.props.period == 'short' || this.props.period == null ? 'font-weight-bold clickable' : 'clickable'} onClick={() => this.props.setPeriod('short')}>3 Months</span> | 
      <span className={this.props.period == 'current_year' ? 'font-weight-bold clickable' : 'clickable'} onClick={() => this.props.setPeriod('current_year')}> 365 Days</span> | 
      <span className={this.props.period == 'alltime' ? 'font-weight-bold clickable' : 'clickable'} onClick={() => this.props.setPeriod('alltime')}> All Time</span>
      </>
    )
  }
  getGraphData = () => {
    if (this.props.graphData) {
      return {
        labels: this.props.graphData.map(x => x.date),
        datasets: [
          {
            label: 'Actual Profit',
            fill: false,
            borderColor: '#28a745',
            lineTension: 0.8,
            pointRadius: 0,
            data: this.props.graphData.map(x => x.profit_running*500)
          },
          {
            label: 'Expected Value',
            fill: false,
            borderColor: '#3581B8',
            lineTension: 0.8,
            pointRadius: 0,
            data: this.props.graphData.map(x => x.ev_running*500)
          }
        ]
      }
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="offset-md-2 col-md-8 col-12 text-center">
            <h2>Graph</h2>
            {this.renderPeriodSelect()}
            <br />
            {this.renderTypeSelect()}
            <br />
            <Line data={this.getGraphData()} options={this.getOptions()}/>
          </div>
        </div>
      </div>
    );
  }
};


export default Graph
