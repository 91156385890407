import React from 'react';
import Axios from 'axios';
import _ from 'lodash';
import Race from './race/race';

class Meetings extends React.Component {

  getDate = (offset = 0) => {
    let todaysDate = new Date()
    todaysDate.setDate(todaysDate.getDate() + offset)
    let date = todaysDate.toISOString().split("/").join("-").split("T")[0]
    return date
  }

  convertDateToLabel = (dateString) => {
    const inputDate = new Date(dateString);
    inputDate.setHours(0,0,0,0);
    const today = new Date().setHours(0,0,0,0);
    const diff = (inputDate - today) / (1000 * 60 * 60 * 24);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return diff === 0 ? 'Today' : 
           diff === -1 ? 'Yesterday' : 
           diff === 1 ? 'Tomorrow' : 
           daysOfWeek[inputDate.getDay()];
  }


  state = {
    meetings: [],
    race_code: 1,
    race: null,
    date: this.getDate()
  };

  componentDidMount = () => {
    let todaysDate = new Date()
    todaysDate.setDate(todaysDate.getDate()).toLocaleString('en-AU', { timeZone: 'Australia/Sydney' })
    let date = todaysDate.toISOString().split("/").join("-").split("T")[0]
    this.getMeetings(date).then((meetings) => { this.setState({meetings: meetings, race: meetings[0]})})
  }

  getMeetings = async (date) => {
    let meetings = await Axios.get(`${process.env.REACT_APP_API_URL}/api/meetings/?date=${date}`)
      .then((res) => {
        return res['data']['data']
      })
    if (meetings) {
      return meetings
    } else {
      return []
    }
  }

  updateMeetingsState = async (date) => {
    let meetings = await this.getMeetings(date)
    this.setState({meetings: meetings, race: meetings[0], date: date})
  }

  getMaxRaceNumber = () => {
    if (this.state.meetings.length > 0) {
      let codeMeetings = this.state.meetings.filter((x) => x.race_code === this.state.race_code)
      if (codeMeetings.length > 0) {
        let max = Math.max(...codeMeetings.map((x) => x.race*1))
        return max
      }
    }
  }

  renderTipInRace = (race) => {
    let tip = this.props.tips.filter((x) => x.race_key == race.race_key)
    if (tip.length > 0) {
      return <span className='fa fa-circle horse-in-race fa-highlighted'></span>
    } else {
      return null
    }
  }

  renderFixedOddsAvailable = (race) => {
    return race.fixed_odds_available === 1 ? '' : 'text-secondary'
  }

  renderTimeTo = (time) => {
    if (time == null) {
      return time
    } else {
      let t = time // time to race in minutes
      if (t < 0) {
        return 'Pending'
      } else if (t == 0) {
        return `Now`
      } else if (t < 60) {
        return `${Number(t).toFixed()} Mins`
      } else {
        return `${Number(t/60).toFixed()} Hrs`
      }
    }
  }

  renderResultsOrTimeTo = (meeting) => {
    if (meeting.results == null) {
      return this.renderTimeTo(meeting.time_to)
    } else {
      return meeting.results
    }
  }

  renderTableHeaders = () => {
    return [...Array(this.getMaxRaceNumber()).keys()].map((i) => {
      return <th>{i+1}</th>
    })
  }

  renderRaceCell = (race) => {
    if (race == this.state.race) {
      return (
        <td className={'race-cell selected ' + this.renderFixedOddsAvailable(race)} onClick={() => this.setState({...this.state, race: race})}>
          {this.renderTipInRace(race)}
          {this.renderResultsOrTimeTo(race)}
        </td>
      )
    } else {
      return (
        <td className={'race-cell ' + this.renderFixedOddsAvailable(race)} onClick={() => this.setState({...this.state, race: race})}>
          {this.renderTipInRace(race)}
          {this.renderResultsOrTimeTo(race)}
        </td>
      )
    }
  }

  renderMeetings = () => {
    if (this.state.meetings.length > 0) {
      let codeMeetings = this.state.meetings.filter((x) => x.race_code === this.state.race_code)
      let groupedMeetings = _.groupBy(codeMeetings, 'meeting')
      return Object.keys(groupedMeetings).map((meeting) => {
        return <tr><td><b>{meeting}</b></td>{groupedMeetings[meeting].map((x) => this.renderRaceCell(x))}</tr>
      })
    }
  }

  renderHighlight = (date) => {
    if (date == this.state.date) {
      return 'text-highlight'
    } else {
      return ''
    }
  }

  renderDates = () => {
    return [-1,0,1,2,3,4].map((offset) => {
      let date = this.getDate(offset)
      let label = this.convertDateToLabel(date)
      return <><span className={'pointer ' + this.renderHighlight(date)} onClick={() => this.updateMeetingsState(date)} date={date}>{label}</span><span> | </span></>
    })
  }

  renderMeetingsTable = () => {
    if (this.state.meetings.length > 0) {
      let codeMeetings = this.state.meetings.filter((x) => x.race_code === this.state.race_code)
      if (codeMeetings.length > 0) {
        return (
          <table className='text-center table table-sm horse-table meetings-table rounded'>
            <tbody>
              <tr className='meetings-header'>
                <th>Location</th>
                {this.renderTableHeaders()}
              </tr>
              {this.renderMeetings()}
            </tbody>
          </table>
        )
      } else {
        return <div><br />No Meetings found for this date.</div>
      }
    } else {
      return <div><br />No Meetings found for this date.</div>
    }
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="offset-md-2 col-md-8 col-xs-12 text-center">
            <h2>Meetings</h2>
            <p>
              {this.renderDates()}
            </p>
            <p>
              <span className={'pointer ' + (this.state.race_code === 1 ? 'text-highlight' : '')} onClick={() => this.setState({race_code: 1})}>Horses</span><span> | </span>
              <span className={'pointer ' + (this.state.race_code === 4 ? 'text-highlight' : '')} onClick={() => this.setState({race_code: 4})}>Dogs</span>
            </p>
            <br />
            <div className='meetings-container'>
              {this.renderMeetingsTable()}
            </div>
            <br />
          </div>
        </div>
        <div className='row'>
          {this.state.race && this.state.race_code == this.state.race.race_code ? <Race getTips={this.props.getTips} race={this.state.race} tips={this.props.tips} curPortfolio={this.props.curPortfolio}/> : null }
        </div>
      </>
    );
  }
};


export default Meetings;
