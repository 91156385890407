import React from 'react';
import Axios from 'axios';

class CreatePortfolio extends React.Component {
  state = {portfolioNameInput: ''}

  handlePortfolioNameInput = (e) => {
    this.setState({portfolioNameInput: e.target.value})
  }

  createPortfolio = (e) => {
    e.preventDefault()
    Axios.post(`${process.env.REACT_APP_API_URL}/api/portfolios/create`,
      {name: this.state.portfolioNameInput},
      {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}).then((res) => {
        if(res['data']['response'] === 'success') {
          this.props.getPortfolioData()
        }
    })
  }

  deletePortfolio = () => {
    const conf = window.confirm(`Are you sure you wish to delete ${this.props.curPortfolio.name}?\n\nThis will also delete all the portfolios associated tips.`);
    if (conf === true) {
      Axios.delete(`${process.env.REACT_APP_API_URL}/api/portfolios/delete`,
      {
        data: {
          "portfolio_id": this.props.curPortfolio.id
        },
        headers: {
          'Authorization': `bearer ${localStorage['authentication']}`,
          'Content-Type': 'application/json'}
        }).then((res) => {
          if(res['data']['response'] === 'success') {
            this.props.getPortfolioData()
          }
    })
    }
  }

  renderDelete = () => {
    if (this.props.curPortfolio) {
      return (
        <div>
          <hr />
          <br />
          <div className='row'>
            <div className="offset-md-2 col-md-8 text-center">
              <button className={`btn btn-delete form-control`} onClick={this.deletePortfolio}>Delete Portfolio</button>
            </div>
          </div>
        </div>
      )
    } else {
      return
    }
  }

  render() {
    return (
      <div className="container">
        <form onSubmit={this.createPortfolio}>
          <div className="row">
            <div className="offset-md-2 col-md-8 text-center">
              <h2>Create New Portfolio</h2>
            </div>
          </div>
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <input type="text" value={this.state.portfolioNameInput} onChange={this.handlePortfolioNameInput} className="form-control horse-search" placeholder='Portfolio Name..'></input>
            </div>
          </div>
          <div className='row'>
              <div className="offset-6 col-6 offset-md-6 col-md-4">
                <button className='btn sexy-button form-control'>Create</button>
              </div>
          </div>
        </form>
        <br />
        {this.renderDelete()}
        <br />
      </div>
    );
  }
};


export default CreatePortfolio;
