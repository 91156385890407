import React from 'react';

class Tips extends React.Component {

  beautifyResult = (res, placePays) => {
    let pos = parseInt(res)
    let pays = parseInt(placePays)
    if (pos === 1) {
      return <span className='font-weight-bold text-success'>{res}</span>
    } else if (pos <= pays) {
      return <span className='font-weight-bold text-warning'>{res}</span>
    } else if (pos > pays) {
      return <span className='text-muted'>{res}</span>
    } else {
      return " "
    }
  }

  renderTimeTo = (tip) => {
    if (tip.timeTo == null) {
      return tip.time
    } else {
      let t = tip.timeTo // time to race in minutes
      if (t < 0) {
        return 'Pending'
      } else if (t == 0) {
        return `Now`
      } else if (t < 60) {
        return `${Number(t).toFixed()} Mins`
      } else if (t < 1440) {
        return `${Number(t/60).toFixed()} Hrs`
      } else {
        return `${Number(t/1440).toFixed()} Days`
			}
    }
  }

  calcPercentage = (odds, current) => {
    if (!odds || !current) {
      return 
    } else {
      let dif = ((parseFloat(odds) - parseFloat(current)) / parseFloat(odds)) * 100
      if (dif > 0) {
        return (
          <span className="text-success">{`${dif.toPrecision(2)}%`}</span>
        )
      } else if (dif < -100) {
        return (
          <span className="text-danger">{`${dif.toPrecision(3)}%`}</span>
        ) 
      } else if (dif < 0) {
        return (
          <span className='text-danger'>{`${dif.toPrecision(2)}%`}</span>
        )
      } else {
        return (
          <span className="text-warning">{`${dif.toPrecision(2)}%`}</span>
        )
      }
    }
  }

  renderName = (tip) => {
    if (tip.deductions*1.0 >= 10) {
      return (
        <td>{tip.runner} <span class="far fa-comment-dots" onClick={() => {window.alert(`${tip.runner} has ${tip.deductions}c win deductions and ${tip.place_deductions}c place deductions`)}}></span></td>
      )
    } else {
      return <td>{tip.runner}</td>
    }
  }

  renderType = (tip) => {
    if (tip.race_code === 1) {
      return (
        <td>
          <img src='/thoroughbred.png' className='img-thumb'></img>
        </td>
      )
    } else if (tip.race_code === 4) {
      return (
        <td>
          <img src='/greyhound.png' className='img-thumb'></img>
        </td>
      )
    } 
  }

  renderTips = () => {
    if(!this.props.tips.length) {
      return <tr></tr>
    } else {
      return this.props.tips.filter((tip) => {return (tip.result !== null || tip.curStatus === 'S')} ).map((tip) => {
        let actualWin = (tip.win * (1 - (tip.deductions / 100))).toPrecision(3);
        let actualPlace = (tip.place * (1 - (tip.place_deductions / 100))).toPrecision(3);
        return (
          <tr key={tip.id} className={(tip.result === 'V' || tip.curStatus === 'S') ? 'text-danger' : ''}>
            {this.renderType(tip)}
            <td>
              {tip.date}
            </td>
            <td>
            {`${tip.meeting} R${tip.race}#${tip.number}`}
            </td>
            {this.renderName(tip)}
            <td>
              <div>{actualWin}</div>
              <div>{actualPlace}</div>
            </td>
            <td>
              <div>{tip.sp}</div>
              <div>{tip.place_sp}</div>
            </td>            
            <td>
              {this.beautifyResult(tip.result, tip.place_pays)}
            </td>
            <td>
            {tip.result !== 'V' ? this.calcPercentage(actualWin, tip.sp) : ''}
            </td>
          </tr>
        );
      });
    }
  };

  renderPendingTips = () => {
    if(!this.props.tips.length) {
      return <tr></tr>
    } else {
      return this.props.tips.filter((tip) => { return (tip.result === null && tip.curStatus !== 'S')}).sort((a, b) => { return b.timeTo - a.timeTo}).map((tip) => {
        let actualWin = (tip.win * (1 - (tip.deductions / 100))).toPrecision(3);
        let actualPlace = (tip.place * (1 - (tip.place_deductions / 100))).toPrecision(3);
        return (
          <tr key={tip.id} className={tip.result === 'V' ? 'text-danger' : ''}>
            {this.renderType(tip)}
            <td>
              {this.renderTimeTo(tip)}
            </td>
            <td>
              {`${tip.meeting} R${tip.race}#${tip.number}`}
            </td>
            {this.renderName(tip)}
            <td>
              <div>{actualWin}</div>
              <div>{actualPlace}</div>
            </td>
            <td>
              <div>{tip.curWin}</div>
              <div>{tip.curPlace}</div>
            </td>
            <td>
              {this.calcPercentage(actualWin, tip.curWin)}
            </td>
          </tr>
        );
      });
    }
  };

  render() {
    return (
      <div>
      <div className="row">
        <div className="offset-md-2 col-md-8 col-12 text-center">
          <h2>Pending Tips</h2>
          <br />
          <table className='text-center table table-sm horse-table rounded'>
            <tbody>
              <tr>
                <th>Type</th>
                <th>Time</th>
                <th>Race</th>
                <th>Runner</th>
                <th>Odds</th>
                <th>CurOdds</th>
                <th>Change</th>
              </tr>
              {this.renderPendingTips()}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="offset-md-2 col-md-8 col-12 text-center">
          <br />
          <h2>Resulted Tips</h2>
          <br />
          <table className='text-center table table-sm horse-table rounded'>
            <tbody>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>Race</th>
                <th>Runner</th>
                <th>Odds</th>
                <th>SP</th>
                <th>Result</th>
                <th>Change</th>
              </tr>
              {this.renderTips()}
            </tbody>
          </table>
        </div>
      </div>
      </div>
    );
  }
};


export default Tips
