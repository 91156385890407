import React from 'react';
import { Redirect } from "react-router-dom";
import Axios from 'axios';

class Header extends React.Component {

  componentDidMount = () => {
    this.timer = setInterval(()=> this.props.getTips(), 60000);
    this.props.getPortfolioData()
  }


  componentWillUnmount() {
    clearInterval(this.timer)
    this.timer = null; // here...
  }

  toggleDropdown = () => {
    document.getElementById('dd').classList.toggle('header__dropdown--collapse')
    document.getElementById('spin-caret').classList.toggle('spin')
  }

  filterPortfolio = (e) => {
    const curPorfId = parseInt(e.target.className.split('-').pop());
    const curPorf = this.props.portfolios.filter((x) => { return parseInt(x.id) === curPorfId })[0];
    this.props.getCurPortfolio(curPorf);
    this.toggleDropdown()
  }

  renderToggleStats = () => {
    if (this.props.showStats) {
      return <li onClick={() => {this.props.toggleStats(); this.toggleDropdown();}}><p>Hide Stats <span className='fas fa-chart-bar'></span></p></li>
    } else {
      return <li onClick={() => {this.props.toggleStats(); this.toggleDropdown();}}><p>Show Stats <span className='fas fa-chart-bar'></span></p></li>
    }
  }

  renderPortfolios = () => {
    if (this.props.portfolios && this.props.curPortfolio) {
      return this.props.portfolios.filter((x) => { return x.id !== this.props.curPortfolio.id && x.visible === 1 })
      .map((x) => {
        return (
          <li className={`portfolio-id-${x.id}`} key={x.id} onClick={this.filterPortfolio}>
            <p className={`portfolio-id-${x.id}`}>{x.name.slice(0,10)}</p>
          </li>
        )
      })
    }
  }
  render() {
    if (!this.props.user) {
      return <Redirect to='/' />
    }
    return (
      <div className='header-container'>
        <div className="header">
          <div className="header__logo">
            <img className='header__logo-img' src="/blue_icon.png" alt='logo'></img>
          </div>
          <div className="header__logout">
            <p>Portfolio</p>
          </div>
          <div className='header__content' onClick={this.toggleDropdown}>
            <div className='current-portfolio'>
              {this.props.curPortfolio ? this.props.curPortfolio.name.slice(0,10) : "Portfolio"}
              <span className="caret-container">
                <span className="fas fa-caret-down" id='spin-caret'></span>
              </span>
            </div>
          </div>
          <div className='header__dropdown header__dropdown--collapse' id='dd'>
            <ul>
              {this.renderPortfolios()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
};

export default Header
