import React from 'react';
import Axios from 'axios';
import _ from 'lodash';
import Horse from './horse';

class Result extends React.Component {

  beautifyResult = (res, placePays) => {
    let pos = parseInt(res)
    if (pos === 1) {
      return <span className='font-weight-bold text-success'>{res}.</span>
    } else if (pos <= placePays) {
      return <span className='font-weight-bold text-warning'>{res}.</span>
    } else {
      return <span className='font-weight-bold text-muted'>{res}.</span>
    }
  }

  renderIsTipped = (isTipped) => {
    if (isTipped) {
      return <span className='fa fa-circle horse-in-race fa-highlighted'></span>
    } else {
      return null
    }
  }

  isHorseTipped = (horse) => {
    let tip = this.props.tips.filter((x) => x.runner_key == horse.runner_key)
    if (tip.length > 0) {
      return true
    } else {
      return false
    }
  }

  renderPositions = () => {
    return this.props.results.map((x) => {
      return (
        <tr>
          <td>{this.beautifyResult(x.result, this.props.placePays)}</td>
          <td>
            {this.renderIsTipped(this.isHorseTipped(x))}
            {x.number}. {x.runner}
          </td>
          <td>{x.win_sp}</td>
          <td>{x.place_sp}</td>
        </tr>
      )
    })
  }

  render() {
    return (
      <div>
        <table className='text-center table table-sm results-table meetings-table rounded'>
          <tbody>
            <tr>
              <th>Position</th>
              <th>Runner</th>
              <th>Win</th>
              <th>Place</th>
            </tr>
            {this.renderPositions()}
          </tbody>
        </table>
      </div>
    );
  }
};


export default Result;
