import React from 'react';

class Stats extends React.Component {

  beautifyResult = (res) => {
    let pos = parseInt(res)
    if (pos === 1) {
      return <span className='font-weight-bold text-success'>{res}</span>
    } else if (pos === 2 || pos === 3) {
      return <span className='font-weight-bold text-warning'>{res}</span>
    } else if (pos === 4) {
      return <span className='text-muted'>{res}</span>
    } else {
      return " "
    }
  }

  colorNumber = (num) => {
    let numb = parseFloat(num) * 100
    if (numb > 0) {
      return <span className="text-success">{`${numb.toPrecision(3)}%`}</span>
    } else if (numb === 0) {
      return <span className="text-warning">{`${numb.toPrecision(3)}%`}</span>
    } else if (numb < 0) {
      return <span className="text-danger">{`${numb.toPrecision(3)}%`}</span>
    } else {
      return " "
    }
  }

  calcPercentage = (odds, current) => {
    if (!odds || !current) {
      return 
    } else {
      let dif = ((parseFloat(odds) - parseFloat(current)) / parseFloat(odds)) * 100
      if (dif > 0) {
        return (
          <span className="text-success font-weight-bold">{`${dif.toPrecision(3)}%`}</span>
        )
      } else if (dif < -100) {
        return (
          <span className="text-danger font-weight-bold">{`${dif.toPrecision(3)}%`}</span>
        ) 
      } else if (dif < 0) {
        return (
          <span className='text-danger font-weight-bold'>{`${dif.toPrecision(3)}%`}</span>
        )
      } else {
        return (
          <span className="text-warning font-weight-bold">{`${dif.toPrecision(3)}%`}</span>
        )
      }
    }
  }

  renderStats = () => {
    if(!this.props.curPortfolio) {
      return <tr></tr>
    } else {
      let port = this.props.curPortfolio
      return (
        <tr key={port.id}>
          <td>
            {port.total}
          </td>
          <td>
            {port.tipsPerDay}
          </td>
          <td>
            <p>{port.AvWin}</p>
            {port.AvPlace}
          </td>
          <td>
            <p>{port.AvSP}</p>
            {port.AvPlaceSP}
          </td>
          <td>
            <p>{this.calcPercentage(port.AvWin,port.AvSP)}</p>
            <p>{this.calcPercentage(port.AvPlace,port.AvPlaceSP)}</p>
          </td>
          <td>
            <p>{this.colorNumber(port.winPOT)}</p>
            {this.colorNumber(port.placePOT)}
          </td>
          <td>
            <p>{this.colorNumber(port.winStrike)}</p>
            {this.colorNumber(port.placeStrike)}
          </td>
        </tr>
      );
    }
  };


  render() {
    return (
      <div>
        <div className="row">
          <div className="offset-md-2 col-md-8 col-12 text-center">
            <h2>Stats</h2>
            <br />
            <table className='text-center table table-sm horse-table rounded'>
              <tbody>
                <tr>
                  <th>Tips</th>
                  <th>Tips/Day</th>
                  <th>Av. Odds</th>
                  <th>Av. SP</th>
                  <th>Change</th>
                  <th>POT</th>
                  <th>Strike Rate</th>
                </tr>
                {this.renderStats()}
              </tbody>
            </table>
          </div>
        </div>
      <br />
      </div>
    );
  }
};


export default Stats
