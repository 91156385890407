import React from 'react';
import Axios from 'axios';

class Horses extends React.Component {
  state = {
    q: null,
    horses: []
  };

  getData = (e) => {
    Axios.get(`${process.env.REACT_APP_API_URL}/api/horses/search?q=${e.target.value}`)
      .then((response) =>{
        this.setState({horses: response['data']['data']})
      });
  };

  addTip = (e) => {
    let runnerKey = e.target.className.split('--').pop()
    let portfolioId = this.props.curPortfolio.id
    let ev = e.target;
    if (portfolioId) {
      e.target.disabled = true;
      e.target.innerHTML = "<div class='spinner-border'></div>"
      Axios.post(`${process.env.REACT_APP_API_URL}/api/portfolios/${portfolioId}/tips/create`,
      {runner_key: runnerKey},
      {headers: {'Authorization': `bearer ${localStorage['authentication']}`}}
      ).then((res) => {
        ev.disabled = false;
        ev.innerHTML = '+';
	if (!res['data'].response) {
          window.alert("You can't build there, somethings in the way.")
	} else {
          this.props.getTips();
	}
      })
    }
  }

  renderButton = (horse) => {
    let tip = this.props.tips.filter((x) => x.runner_key == horse.runner_key)
    if (tip.length > 0) {
      return <button className={'btn btn-sm bg-success'}><span className='fa fa-check'></span></button>
    } else {
      return <button className={`btn btn-sm horse-id--${horse.runner_key}`} onClick={this.addTip}> + </button>
    }
  }

  renderHorsies = () => {
    if(!this.state.horses.length) {
      return <tr></tr>
    } else {
      return this.state.horses.map((horse) => {
        return (
          <tr key={horse.id}>
            <td>
              {horse.date}
            </td>
            <td>
              {horse.meeting}
            </td>
            <td>
              {horse.race}
            </td>
            <td>
              {horse.runner}
            </td>
            <td>
              <div>{horse.win}</div>
              <div>{horse.place}</div>
            </td>
            <td>
              {this.renderButton(horse)}
            </td>
          </tr>
        );
      });
    }
  };

  renderSearchResults = () => {
    if (this.state.horses.length > 0) {
      return (
          <table className='text-center table table-sm horse-table rounded'>
            <tbody>
              <tr>
                <th>Date</th>
                <th>Meeting</th>
                <th>Race</th>
                <th>Runner</th>
                <th>Odds</th>
                <th>Add</th>
              </tr>
              {this.renderHorsies()}
            </tbody>
          </table>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div className="row">
        <div className="offset-md-2 col-md-8 col-xs-12 text-center">
          <h2>Add Tips</h2>
          <input className='input form-control horse-search rounded' onKeyUp={this.getData} placeholder='Search for horses..'></input>
          {this.renderSearchResults()}
        </div>
      </div>
    );
  }
};


export default Horses;
