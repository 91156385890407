import React from 'react';
import { Redirect } from "react-router-dom";
import Axios from 'axios';

class Login extends React.Component {
  state = {username: '', password: ''}

  handleUsername = (e) => {
    this.setState({username: e.target.value})
  }
  handlePassword = (e) => {
    this.setState({password: e.target.value})
  }
  handleLogin = (e) => {
    e.preventDefault()
    Axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`,
    {
      'username': this.state.username,
      'password': this.state.password,
    }).then((res) => {
      if (res['data']['response'] !== 'success') {
        window.alert("Incorrect username or password.")
      } else {
        localStorage.setItem('authentication', res['data']['data']);
        this.props.login()
      }
    });
  };

  handleLogout = (e) => {
    e.preventDefault()
    this.props.logout()
  }

  render() {
    if (this.props.user) {
      return <Redirect to='/app' />
    }
    return (
      <div className='login'>
        <form className='login__form' onSubmit={this.handleLogin}>
          <div className="container">
            <div className="row">
              <div className="offset-xs-2 col-md-6 offset-md-3 col-xs-8">
                <input className='rounded form-control' value={this.state.username} placeholder='Username..' onChange={this.handleUsername}></input>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <input className='rounded form-control' type='password' value={this.state.password} placeholder='Password..' onChange={this.handlePassword}></input>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="offset-md-6 offset-6 col-md-3 col-6 col-sm-6 offset-sm-6">
                <button className='rounded form-control'>Login</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
};


export default Login
